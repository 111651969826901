import React from 'react';
import { NavLink } from 'react-router-dom';
import {
    Typography,
    Box,
} from '@mui/material';
import styled from "@emotion/styled";

export default function MainPage() {
    const Root = styled.div`
    max-width: 1000px;
    margin: 20px auto 0;
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    padding: 0 2rem;
    `;
    return (
        <>
            <Root>
                <div p={4} sx={{ backgroundColor: 'transparent', position: 'relative', minHeight: '100vh' }}>
                    <Typography py={1} variant='h5' sx={{ fontFamily: 'Arial, sans-serif' }}>
                        Bienvenue ! Je suis
                    </Typography>
                    <Typography variant='h3' sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                        Gatien HADDAD
                    </Typography>
                    <Typography py={2} variant='h4' sx={{ marginBottom: '10px' }}></Typography>
                    <Typography py={2} variant='h6'>
                        Je suis actuellement en première année de thèse chez <span className='special_ext'><a href="https://pradeo.com/" rel="noopener noreferrer" target="_blank">Pradeo</a></span>, après avoir obtenu un Master <span className='special_ext'><a href="https://informatique-fds.edu.umontpellier.fr/etudiants/cmi-informatique-cursus-master-en-ingenierie/" rel="noopener noreferrer" target="_blank">CMI</a></span> spécialité Intelligence Artificielle et Sciences des Données.<br />
                        Vous pouvez retrouver sur mon site les <span className='special'><a href="/#projets">projets</a></span> sur lesquels j'ai travaillé, ainsi que mon <span className='special'><a href="/#documents">CV</a></span> et autres documents.
                    </Typography>
                    <Typography py={1} variant='h6'>
                        N'hésitez pas à me <span className='special'><a href="/#contact">contacter</a></span> !
                    </Typography>

                </div>
            </Root>
            <Box p={1}></Box>
        </>
    )
}