import React from 'react';
import { useCookies } from 'react-cookie';
import { HelmetProvider, Helmet } from "react-helmet-async";

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';

import Routes from './routes';
import Footer from './pages/layouts/footer';

import MainPage from './pages';
import Projects from './pages/projects';
import CV from './pages/CV';
import Contact from './pages/contact';

import LightTheme from './themes/light';
import DarkTheme from './themes/dark';
import NavBar from './pages/layouts/nav';

export default function App() {
    const [cookies] = useCookies(['mode']);

    return (
        <HelmetProvider>
            <Helmet titleTemplate="GatienH-Portfolio | %s" defaultTitle="GatienH-Portfolio" />
            <ThemeProvider theme={cookies.mode === 'dark' ? DarkTheme : LightTheme}>
                <CssBaseline />
                <Box display="flex" flexDirection="column" minHeight="100vh">
                    <NavBar />

                    <Box flexGrow={1} mt={20}>
                        <div className="main-content">
                            <section id="main">
                                <MainPage />
                            </section>

                            <section id="projets">
                                <Projects />
                            </section>

                            <section id="documents">
                                <CV />
                            </section>

                            <section id="contact">
                                <Contact />
                            </section>
                        </div>
                    </Box>

                    <Footer />

                </Box>
            </ThemeProvider>
        </HelmetProvider>
    );
}
