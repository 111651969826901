import React from 'react';
import { useState } from 'react';
import { useCookies } from 'react-cookie';

import {
    styled,
    Card,
    Typography,
    CardHeader,
    CardMedia,
    CardContent,
    Collapse,
    Avatar,
    AvatarGroup,
    IconButton,
    Stack
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GitHubIcon from '@mui/icons-material/GitHub';
import LaunchIcon from '@mui/icons-material/Launch';


const languages_img = {
    "python": "https://profilinator.rishav.dev/skills-assets/python-original.svg",
    "pytorch": "https://profilinator.rishav.dev/skills-assets/pytorch-icon.svg",
    "js": "https://profilinator.rishav.dev/skills-assets/javascript-original.svg",
    "ts": "https://profilinator.rishav.dev/skills-assets/typescript-original.svg",
    "jquery": "https://profilinator.rishav.dev/skills-assets/jquery.png",
    "mysql": "https://profilinator.rishav.dev/skills-assets/mysql-original-wordmark.svg",
    "oracle": "https://profilinator.rishav.dev/skills-assets/oracle-original.svg",
    "php": "https://profilinator.rishav.dev/skills-assets/php-original.svg",
    "nodejs": "https://profilinator.rishav.dev/skills-assets/nodejs-original-wordmark.svg",
    "mongodb": "https://profilinator.rishav.dev/skills-assets/mongodb-original-wordmark.svg",
    "html": "https://profilinator.rishav.dev/skills-assets/html5-original-wordmark.svg",
    "css": "https://profilinator.rishav.dev/skills-assets/css3-original-wordmark.svg",
    "sass": "https://profilinator.rishav.dev/skills-assets/sass-original.svg",
    "latex": "https://profilinator.rishav.dev/skills-assets/latex.png",
    "react": "https://profilinator.rishav.dev/skills-assets/react-original-wordmark.svg",
    "c++": "https://profilinator.rishav.dev/skills-assets/cplusplus-original.svg",
    "c": "https://profilinator.rishav.dev/skills-assets/c-original.svg",
    "java": "https://profilinator.rishav.dev/skills-assets/java-original-wordmark.svg",
    "git": "https://profilinator.rishav.dev/skills-assets/git-scm-icon.svg",
    "android": "https://profilinator.rishav.dev/skills-assets/android-original-wordmark.svg",
    "firebase": "https://profilinator.rishav.dev/skills-assets/firebase.png",
    "electron": "https://profilinator.rishav.dev/skills-assets/electron-original.svg",
    "linux": "https://profilinator.rishav.dev/skills-assets/linux-original.svg"
};





const ExpandMore = styled(
    (props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    }
)(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function CardUnit({ Languages, GitHubRepo, Site, Name, Date, Image, Comment, Description, PreviewURL }) {

    const [expanded, setExpanded] = useState(false);
    const [cookies] = useCookies(['mode']);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const link = GitHubRepo != '' ? GitHubRepo : Site;

    return (
        <Card className="card" sx={{ maxWidth: 450, margin: '0', boxShadow: 3, borderRadius: 3 }}>
            <div onClick={handleExpandClick} sx={{ color: 'inherit', '&:hover': { color: 'primary.main' } }}>
                <CardMedia
                    component='img'
                    height='300'
                    image={require('../../static/images/projects/' + Image)}
                    alt={Name}
                    sx={{ margin: "auto", borderRadius: 5, backgroundColor: 'background.light' }}
                />
                <CardHeader
                    avatar={
                        <AvatarGroup max={4}>
                            {Languages.map((index) => (
                                <a key={index} rel="noopener noreferrer" target="_blank" title={index} >
                                    {<Avatar alt={index} sx={{ backgroundColor: 'white' }}
                                        src={languages_img[index]} />}
                                </a>
                            ))}
                        </AvatarGroup>
                    }
                    action={
                        <a href={link} rel='noopener noreferrer' target='_blank'>
                            <IconButton aria-label='repo' sx={{ color: 'text.primary', '&:hover': { color: 'primary.main' } }}>
                                {GitHubRepo != '' && <GitHubIcon />}
                                {Site != '' && <LaunchIcon />}
                            </IconButton>
                        </a>
                    }
                    title={Name}
                    subheader={Date}
                    sx={{
                        '& .MuiCardHeader-title': { fontSize: '1.2rem' },
                        '& .MuiCardHeader-subheader': { fontSize: '1rem' },
                    }}
                />
                <CardContent sx={{ color: 'inherit' }}>
                    <Stack direction='row' spacing={1} style={{ color: 'inherit', display: 'flex', alignItems: 'center' }}>
                        <ExpandMoreIcon sx={{ color: 'inherit', '&:hover': { color: 'primary.main' } }} />
                        <Typography variant='body2' color='inherit'>
                            {Comment}
                        </Typography>
                    </Stack>
                </CardContent>
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    <CardContent>
                        <Typography paragraph>
                            {Description}
                        </Typography>
                    </CardContent>
                </Collapse>
            </div>
        </Card>
    );
}
