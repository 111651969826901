import { createTheme } from '@mui/material/styles';

const DarkPalette = createTheme({
    palette: {
        common: {
            black: '#000000',
            white: '#ffffff'
        },
        primary: {
            main: '#14ce12'
        },
        text: {
            primary: '#ffffff',
            secondary: 'rgba(255,255,255,0.6)'
        },
        background: {
            default: '#121212',
            paper: '#2b2d31',
            light: '#4a4a4a'
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
})

export default DarkPalette;