import { React, useState } from 'react';
import {
    Box,
    Button
} from '@mui/material';

import cv_img from '../../static/images/CV/CV.png';
import licence_img from '../../static/images/CV/licence.png';
import toeic_img from '../../static/images/CV/toeic.png';
import voltaire_img from '../../static/images/CV/voltaire.png';

import cv_pdf from '../../static/ressources/CV.pdf';
import licence_pdf from '../../static/ressources/Licence.pdf';
import toeic_pdf from '../../static/ressources/TOEIC.pdf';
import voltaire_pdf from '../../static/ressources/Voltaire.pdf';

export default function Resume() {
    const [currentCv, setCurrentCv] = useState({ img: cv_img, pdf: cv_pdf });

    const handleButtonClick = (img, pdf) => {
        // show div-documents
        document.querySelector('.div-documents').style.display = 'block';
        setCurrentCv({ img, pdf });
    };

    return (
        <>
            {/* Boutons de sélélection */}
            <Box sx={{ display: 'flex', justifyContent: 'center', margin: '0 10px 30px' }}>
                <Button variant='contained' onClick={() => handleButtonClick(cv_img, cv_pdf)} sx={{ boxShadow: 10 }}>CV</Button>
                <Button variant='contained' onClick={() => handleButtonClick(licence_img, licence_pdf)} sx={{ marginLeft: '10px', boxShadow: 10 }}>Diplôme de Licence</Button>
                <Button variant='contained' onClick={() => handleButtonClick(toeic_img, toeic_pdf)} sx={{ marginLeft: '10px', boxShadow: 10 }}>Diplôme du TOEIC</Button>
                <Button variant='contained' onClick={() => handleButtonClick(voltaire_img, voltaire_pdf)} sx={{ marginLeft: '10px', boxShadow: 10 }}>Certificat Voltaire</Button>
            </Box>

            <div className="div-documents" style={{ display: 'none' }}>
                {/* CV */}
                <Box className="container-doc">
                    <Box
                        component='img'
                        alt='CV'
                        src={currentCv.img}
                        className="image-doc"
                    />
                </Box>

                {/* Bouton télécharger */}
                <Box pb={2} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '20px'
                }}>
                    <Button variant='contained' href={currentCv.pdf} download={`${currentCv.img === cv_img ? 'CV' : (currentCv.img === licence_img ? 'Licence' : (currentCv.img === toeic_img ? 'TOEIC' : 'Voltaire'))}_Gatien_HADDAD.pdf`} sx={{ boxShadow: 10 }}>Télécharger</Button>
                </Box>
            </div>
        </>
    )
}