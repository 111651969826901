import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
// import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import GitHubIcon from '@mui/icons-material/GitHub';
// import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function Contact() {
    return (
        <>
            <div className="contact">
                <div className="grid">
                    <div className="titre-contact">Me contacter</div>
                    <div>
                        <EmailIcon style={{ verticalAlign: 'middle' }} />
                        <label htmlFor="email">Email :</label>
                        <span className="value">gatienhaddad@gatienh.fr</span>
                    </div>
                    {/* <div>
                        <LocalPhoneIcon style={{ verticalAlign: 'middle' }} />
                        <label htmlFor="phone">Téléphone :</label>
                        <span className="value">0782235495</span>
                    </div> */}
                    <div>
                        <GitHubIcon style={{ verticalAlign: 'middle' }} />
                        <label htmlFor="git">Github :</label>
                        <span className="value lien" onClick={() => window.open('https://github.com/Leogendra')}>
                            Leogendra
                        </span>
                    </div>
                    <div>
                        <LinkedInIcon style={{ verticalAlign: 'middle' }} />
                        <label htmlFor="LinkedIn">LinkedIn :</label>
                        <span className="value lien" onClick={() => window.open('https://www.linkedin.com/in/gatien-h-4b7034235/')}>
                            Gatien Haddad
                        </span>
                    </div>
                    {/* <div>
                        <SportsEsportsIcon style={{ verticalAlign: 'middle' }} />
                        <label htmlFor="discord">Discord :</label>
                        <span className="value">Leogendra#4060</span>
                    </div> */}
                </div>
            </div>
        </>
    )
}