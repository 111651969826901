const Url = {
    Languages: ['nodejs', 'mongodb'],
    GitHubRepo: '',
    Site: 'https://url.gatienh.fr/',
    Name: 'Url-Shortener',
    Date: 'Janvier 2024',
    Image: 'url.png',
    Comment: 'Raccourcisseur d\'URL',
    Description: 'Un raccourcisseur d\'URL, permettant de raccourcir des URL et de les partager facilement.',
    PreviewURL: 'Url-Shortener',
    Pertinence: 5
};

const Hub = {
    Languages: ['html', 'css', 'js'],
    GitHubRepo: '',
    Site: 'https://hub.gatienh.fr/',
    Name: 'Gatien-Hub',
    Date: 'Aout 2023',
    Image: 'hub.png',
    Comment: 'Site pour regrouper des jeux',
    Description: 'Un site web permettant de regrouper des petits jeux, des projets, des sites web, etc.',
    PreviewURL: 'Gatien-Hub',
    Pertinence: 3
};

const GPGH = {
    Languages: ['android', 'firebase', 'java'],
    GitHubRepo: 'https://github.com/Gaby269/Application-Mobile-Interimaire',
    Site: '',
    Name: 'GPGH-Interimaire',
    Date: 'Fevrier-Mai 2023',
    Image: 'gpgh.png',
    Comment: 'Application pour intérimaires',
    Description: 'Application mobile permettant de gérer et de postuler à des offres d\'emplois, des missions, des contrats, etc.',
    PreviewURL: 'GPGH-Interimaire',
    Pertinence: 5
};

const SpotyCharts = {
    Languages: ['html', 'css', 'js'],
    GitHubRepo: '',
    Site: 'https://spotycharts.gatienh.fr/',
    Name: 'SpotyCharts',
    Date: 'Aout 2023',
    Image: 'spotycharts.png',
    Comment: 'Jeu de connaisances musicales',
    Description: 'Site web pour tester ses connaisances musicales sur les artistes du top monde Spotify.',
    PreviewURL: 'SpotyCharts',
    Pertinence: 5
};

const SnakeAI = {
    Languages: ['python', 'pytorch'],
    GitHubRepo: 'https://github.com/Leogendra/Portfolio-projets/tree/main/Snake-reinforcement-learning',
    Site: '',
    Name: 'Snake AI',
    Date: 'Mars 2023',
    Image: 'snake.png',
    Comment: 'Jeu snake en apprentissage renforcé',
    Description: 'Un agent qui joue à Snake et qui devient de plus en plus fort grâce à de l\'apprentissage renforcé, avec une interface graphique utilisant pygames.',
    PreviewURL: 'Snake-AI',
    Pertinence: 7
};

const SpotyMatch = {
    Languages: ['html', 'css', 'js'],
    GitHubRepo: '',
    Site: 'https://spotymatch.gatienh.fr/',
    Name: 'SpotyMatch',
    Date: 'Mars 2023',
    Image: 'spotymatch.png',
    Comment: 'Jeu de préférences musicales',
    Description: 'Site web en vanilla CSS/JS permettant de déterminer de choisir des musiques deux-à-deux, et de déterminer un classement.',
    PreviewURL: 'SpotyMatch',
    Pertinence: 5
};

const GatienH_Portfolio = {
    Languages: ['react', 'sass'],
    GitHubRepo: '',
    Site: 'https://gatienh.fr/projets',
    Name: 'Portfolio',
    Date: 'Fevrier 2023',
    Image: 'portfolio.png',
    Comment: 'Portfolio réalisé en ReactJS',
    Description: 'Site internet ayant pour but d\'être un portfolio.',
    PreviewURL: 'portfolio',
    Pertinence: 6
};

const quizz_capitales = {
    Languages: ['react', 'sass', 'js'],
    GitHubRepo: 'https://github.com/Leogendra/Quizz-Geographie',
    Site: '',
    Name: 'Quizz Géographie',
    Date: 'Janvier 2023',
    Image: 'quizz_geo.png',
    Comment: 'Web app pour s\'entrainer à la géographie',
    Description: 'Premier projet en ReactJs, il s\'agit d\'une application web permettant de s\'entrainer à reconnaitre les drapeaux des pays et leur capitale.',
    PreviewURL: 'quizz-geographie',
    Pertinence: 5
};

const coloration = {
    Languages: ['c'],
    GitHubRepo: 'https://github.com/Leogendra/Portfolio-projets/tree/main/coloration-de-graphes-repartis',
    Site: '',
    Name: 'Coloration de graphes répartis',
    Date: 'Automne 2022',
    Image: 'coloration.png',
    Comment: "Coloration de clients reliés par des sockets",
    Description: "Ce projet consiste à colorer un graphe constitué de clients reliés par des sockets. Le but est de colorer le graphe de manière à ce que chaque client ait une couleur différente de ses voisins.",
    PreviewURL: 'coloration-de-graphe',
    Pertinence: 1
};

const aoc = {
    Languages: ['python'],
    GitHubRepo: 'https://github.com/Leogendra/Advent-of-Code',
    Site: '',
    Name: 'Advent of Code',
    Date: 'Décembre 2021-2022',
    Image: 'aoc.png',
    Comment: 'Concours de programmation',
    Description: 'Chaque année, l\'Advent of Code se déroule en décembre et propose un problème par jour avant Noel. Il s\'agit de résoudre des problèmes de programmation et d\'algorithmique de difficulté croissante en un minimum de temps.',
    PreviewURL: 'Advent-of-Code',
    Pertinence: 2
};

const Inter2Hut = {
    Languages: ['python', 'git'],
    GitHubRepo: 'https://github.com/Adam-Said/INTER2-HUT',
    Site: '',
    Name: 'INTER2-HUT',
    Date: 'Novembre 2021 - Mai 2022',
    Image: 'inter2hut.png',
    Comment: 'Analyseur de mail avec CLI',
    Description: 'L\'application permet de trier un corpus de mail extrait de Thunderbird fournit par l\'utilisateur et d\'en sortir les marqueurs de l\'interdisciplinarité. Réalisé lors du projet de Licence 3.',
    PreviewURL: 'inter2-hut',
    Pertinence: 2
};

const kavarm = {
    Languages: ['js', 'css', 'mysql', 'php'],
    GitHubRepo: '',
    Site: 'https://kavarm.fr',
    Name: 'Kavarm',
    Date: '2021-2023',
    Image: 'kavarm.png',
    Comment: 'Jeu web type question/réponses',
    Description: "Kavarm est un jeu de type question/réponses en ligne, permettant de jouer à plusieurs en temps réel",
    PreviewURL: 'Kavarm',
    Pertinence: 9
};

const tournois = {
    Languages: ['php', 'mysql', 'js'],
    GitHubRepo: 'https://github.com/Adam-Said/HLIN405-ManageurDeTournois',
    Site: '',
    Name: 'Manageur de Tournois',
    Date: 'Hivers 2021',
    Image: 'tournois.png',
    Comment: 'Application Web pour gestion de tournois',
    Description: "Application web permettant de gérer des tournois en tout genre avec stockage en base de données. Réalisé lors du projet de Licence 2.",
    PreviewURL: 'Manageur-de-Tournois',
    Pertinence: 2
};

// Ordre d'affichage du plus récent au plus vieux
const projectsArray = [
    Url,
    // Hub,
    SpotyCharts,
    GPGH,
    SnakeAI,
    kavarm,
    SpotyMatch,
    GatienH_Portfolio,
    quizz_capitales,
    coloration,
    aoc,
    Inter2Hut,
    tournois
]
export default projectsArray;