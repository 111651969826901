import { React } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { useCookies } from 'react-cookie';
import {
    Container,
    Toolbar,
    Box,
    Button,
    IconButton
} from '@mui/material';

import LightModeIcon from '@mui/icons-material/WbSunnyOutlined';
import DarkModeIcon from '@mui/icons-material/DarkModeOutlined';
import Typography from '@mui/material/Typography';


const pages = [
    { name: 'Accueil', path: '/#main' },
    { name: 'Projets', path: '/#projets' },
    { name: 'Documents', path: '/#documents' },
    { name: 'Contact', path: '/#contact' }
]

export default function NavBar() {
    const [cookies, setCookie, removeCookie] = useCookies(['mode']);

    const handleChange = () => {
        const newMode = (cookies.mode === 'dark' ? 'light' : 'dark');
        if (newMode === 'dark') {
            setCookie('mode', newMode, { path: '/', maxAge: 31536000 });
        }
        else {
            removeCookie('mode', { path: '/' });
        }
    };

    return (
        <div className={cookies.mode === 'dark' ? 'navBarDark' : 'navBar'}>
            <Container maxWidth='x1' sx={{ padding: 0, borderRadius: 10, overflow: 'hidden' }}>
                <Toolbar disableGutters>
                    <Box container py={2} spacing={{ xs: 0, sm: 2, mg: 3, ld: 4, xl: 4 }} sx={{ display: 'flex', flexGrow: 1 }}>
                        <div className='wrapNav'>
                        {pages.map((page) => (
                            <Link smooth to={page.path} className="nav" key={page.name}>
                                <Box item>
                                    <Button size='large' className='textNav' sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                        {page.name}
                                    </Button>
                                </Box>
                            </Link>
                        ))}
                        </div>
                    </Box>
                    <Button variant='text' size='large' onClick={handleChange} sx={{ display: 'flex', alignItems: 'center' }}>
                        <div className='textDarkButton'>
                            {cookies.mode === 'dark' ?
                                (<Typography variant='body2' sx={{ color: 'primary.main', fontWeight: 'bold' }}>Mode sombre</Typography>) :
                                (<Typography variant='body2' sx={{ color: 'primary.main', fontWeight: 'bold' }}>Mode clair</Typography>)
                            }
                        </div>
                        <IconButton size='large' onClick={handleChange}>
                            {cookies.mode === 'dark' ?
                                <DarkModeIcon sx={{ color: 'common.white', backgroundColor: 'primary.main', borderRadius: 5, boxShadow: 5, width: 33, height: 33 }} /> :
                                <LightModeIcon sx={{ color: 'primary.main', backgroundColor: 'background.default', borderRadius: 5, boxShadow: 5, width: 33, height: 33 }} />}
                        </IconButton>
                    </Button>
                </Toolbar>
            </Container>
        </div>
    )
};