import React from 'react';
import { useState } from 'react';
import { Helmet } from "react-helmet-async";
import {
    Grid,
    MenuItem,
    FormControl,
    Select,
    Stack,
    Typography
} from '@mui/material';

import CardUnit from './unit';
import projectsArray from '../../static/data/projects'

export default function Projects() {

    const [sort, setSort] = useState('newest');
    const [filteredProjects, setFilteredProjects] = useState(projectsArray);

    const handleChange = (e) => {
        const selectedValue = e.target.value;

        let filtered = projectsArray.slice();

        if (selectedValue === 'newest') {
            filtered = filtered;
        }
        else if (selectedValue === 'pertinence') {
            filtered = filtered.sort((a, b) => b.Pertinence - a.Pertinence);
        }
        else if (selectedValue === 'web') {
            filtered = filtered.filter((project) =>
                project.Languages.includes("html")
                || project.Languages.includes("css")
                || project.Languages.includes("js")
            );
        }
        else if (selectedValue === 'python') {
            filtered = filtered.filter((project) =>
                project.Languages.includes("python")
            );
        }

        setFilteredProjects(filtered);
        setSort(selectedValue);
    };


    return (
        <>
            <Helmet>
                <title>Projets</title>
            </Helmet>
            <div className='tri-div'>
                <Stack direction='row' spacing={1} pl={3} style={{ margin: "0" }}>
                    <Stack direction='row' spacing={0.0} pt={2}>
                        <Typography color='text.secondary' variant='subtitle1' pl={2}>Trier par</Typography>
                    </Stack>
                    <FormControl sx={{ minWidth: 200, paddingTop: 1.25 }} size='small'>
                        <Select className="tri-select" value={sort} onChange={handleChange} autoWidth>
                            <MenuItem value='newest'>Les plus récents</MenuItem>
                            <MenuItem value='pertinence'>Les plus pertinents</MenuItem>
                            <MenuItem value='web'>Projets web</MenuItem>
                            <MenuItem value='python'>Projets python</MenuItem>
                            {/* autres langages ici */}
                        </Select>
                    </FormControl>
                </Stack>
            </div>
            <Grid container direction='row' justifyContent='center' alignItems='center' p={2}>
                {filteredProjects.map((index) => (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={index.Name} p={2}>
                        <div class="card-div">
                        <CardUnit
                            Languages={index.Languages}
                            GitHubRepo={index.GitHubRepo}
                            Site={index.Site}
                            Name={index.Name}
                            Date={index.Date}
                            Image={index.Image}
                            Comment={index.Comment}
                            Description={index.Description}
                            PreviewURL={index.PreviewURL}
                            Pertinence={index.Pertinence}
                            />
                        </div>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}